<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Dashboard",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
        statData: [
            {
                icon: "bx bx-copy-alt",
                title: "Orders",
                value: "1,235",
            },
            {
                icon: "bx bx-archive-in",
                title: "Revenue",
                value: "$35, 723",
            },
            {
                icon: "bx bx-purchase-tag-alt",
                title: "Average Price",
                value: "$16.2",
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            be_path: process.env.VUE_APP_BACKEND_URL,
            title: "Dashboard",
            tahun: null,
            list_tahun: ["Semua"],
            dashboard_counting: null,
            dashboard_counting_detail: [],
            is_show_detail: false,
            loadingTable: true,
            dataTable: [],
            pagingTable: [],
            showDataToTable: "",
            showDataFromTable: "",
            totalDataTable: "",
            currentTablePage: "",
            excelDownload: "",
            start: null,
            end: null,
            status: null,
            search: null,
            filter_work_type: [],
            filter_work_status: [],
            work_type_selected: "All",
            work_status_selected: "All",
            data_counted: null,
            modal_qr: false,
            data_qr: "",
            id_qr: "",
            id_request_change: null,
            modal_change_status_request: false,
            loading_status: false,
            status_change: [],
            status_change_selected: [],
            catatan_perubahan_status: null,
            hak_akses_add_workpermit: false,
            hak_akses_delete: false,
            matrix_timeline: [],
            timeline: [],
            modal_timeline: false,
            loading_timeline: false,
            permit_timline_id: null,
        };
    },
    mounted() {
        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        var config_type = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_type)
            .then((response) => {
                // console.log(response,'response data type');
                var datatype = ["All"];
                response.data.data[0].data.map((item) => {
                    datatype.push(item.mps_nama);
                });
                this.filter_work_type = datatype;
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
            });
        var config_status = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/status-permohonan",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_status)
            .then((response) => {
                var datastatus = [
                    {
                        id: 0,
                        label: "All",
                    },
                ];
                response.data.data[0].data.map((item) => {
                    datastatus.push({
                        id: item.msp_id,
                        label: item.msp_nama,
                    });
                });
                // console.log(datastatus,'data type');
                this.filter_work_status = datastatus;
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
            });

        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        var self = this;
        axios(config)
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data[0];
                if (response_data.meta.code == 200) {
                    self.loadingTable = false;
                    self.dataTable = response_data_fix.data;
                    self.pagingTable = response_data_fix.links;
                    self.showDataToTable = response_data_fix.to;
                    self.showDataFromTable = response_data_fix.from;
                    self.totalDataTable = response_data_fix.total;
                    self.currentTablePage = response_data_fix.current_page;
                    self.hak_akses_add_workpermit = response_data.data.hak_akses_add_workpermit;
                    self.hak_akses_delete = response_data.data.hak_akses_delete;
                    Swal.close();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        this.getCounting();
    },
    methods: {
        getCounting() {
            var self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/dashboard-counting",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.data_counted = response_data_fix;
                        console.log(self.data_counted, "count data");
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        filterPermohonan() {
            this.loadingTable = true;
            var self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit",
                params: {
                    filter_work_type: self?.work_type_selected,
                    filter_work_status: self?.work_status_selected?.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                        Swal.close();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        toPage(url) {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: url,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        searchTable() {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit",
                params: {
                    search: self.searchDataTable,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        showModal(data_qr) {
            this.data_qr = data_qr;
            this.id_qr = data_qr.id;
            this.modal_qr = true;
        },
        changeStatusData(id, change_type = "delete") {
            let self = this;
            var text = "Are you sure to delete this data?";
            var text_res = "Success delete data.";
            if (change_type == "cancel") {
                text = "Are you sure to cancel this data?";
                text_res = "Success cancel data.";
            }
            Swal.fire({
                title: "Perhatian",
                text: text,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
            }).then((result) => {
                if (result.isConfirmed) {
                    self.loadingTable = true;
                    Swal.fire({
                        title: '<i class="fa fa-refresh fa-spin"></i>',
                        text: "Loading...",
                        showConfirmButton: false,
                    });

                    var config = {
                        method: "delete",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit",
                        data: {
                            id: id,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                    axios(config).then((response) => {
                        var response_data = response.data;
                        if (response_data.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data.data.message,
                            });
                        } else {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: text_res,
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                didOpen: () => {
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getContent();
                                        if (content) {
                                            const b = content.querySelector("b");
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft();
                                            }
                                        }
                                    }, 100);
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.searchTable();
                                }
                            });
                        }
                    });
                } else {
                    Swal.close();
                }
            });
        },
        changeStatusProcess(id) {
            let self = this;
            self.id_request_change = id;
            self.modal_change_status_request = true;
            self.loading_status = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/timeline-status", {
                    params: {
                        id: id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    console.log(response, "response data type");
                    self.loading_timeline = false;
                    self.status_change = response.data.data.list_status;
                    Swal.close();
                })
                .catch((e) => {
                    Swal.close();
                    this.axiosCatchError = e.response.data.data;
                    console.log(this.axiosCatchError);
                });
        },
        prosesPerubahanStatus() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let required_form = new Promise(function (resolve, reject) {
                // cek required
                if (self.catatan_perubahan_status == null || self.catatan_perubahan_status == "") {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan Remark Terisi",
                    });
                    reject("stop");
                }
                resolve("next");
            });
            required_form.then(function (response) {
                if (response == "stop") {
                    return false;
                }

                axios
                    .post(
                        process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/ubah-status-request",
                        {
                            status_submit: "changed",
                            catatan_verifikasi: self.catatan_perubahan_status,
                            next_status_id: self.status_change_selected.mspk_id,
                            id: self.id_request_change,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer " + localStorage.access_token,
                            },
                        }
                    )
                    .then(function (response) {
                        var response_data = response.data;
                        var response_data_fix = response_data.data;
                        console.log(response_data_fix);
                        if (response_data.meta.code == 200) {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Halaman segera diperbarui",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    Swal.close();
                                    window.location.reload();
                                }
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data_fix.data.message,
                            });
                        }
                    })
                    .catch((e) => {
                        self.axiosCatchError = e.response;
                        console.log(e);
                    });
            });
        },
        getBgList(tanggal_berakhir) {
            if (tanggal_berakhir) {
                let date1 = new Date();
                let date2 = new Date(tanggal_berakhir);

                // To calculate the time difference of two dates
                let Difference_In_Time = date2.getTime() - date1.getTime();

                // To calculate the no. of days between two dates
                let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

                // To display the final no. of days (result)
                // console.log("Total number of days between dates:\n" + date1.toDateString() + " and " + date2.toDateString() + " is: " + Difference_In_Days + " days");
                var bg = "bg-success";
                if (Difference_In_Days <= 3) {
                    bg = "bg-warning";
                }
                if (Difference_In_Days <= 1) {
                    bg = "bg-danger";
                }
                return bg;
            } else {
                return null;
            }
        },
        getDays(tanggal_berakhir) {
            if (tanggal_berakhir) {
                let date1 = new Date();
                let date2 = new Date(tanggal_berakhir);

                // To calculate the time difference of two dates
                let Difference_In_Time = date2.getTime() - date1.getTime();

                // To calculate the no. of days between two dates
                let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
                if (Difference_In_Days < 0) {
                    return "Permit Expired";
                } else {
                    return Difference_In_Days + " Days";
                }
                // To display the final no. of days (result)
                // console.log("Total number of days between dates:\n" + date1.toDateString() + " and " + date2.toDateString() + " is: " + Difference_In_Days + " days");

                // if (Difference_In_Days <= 3) {
                //     return "bg-warning";
                // } else if (Difference_In_Days <= 1) {
                //     return "bg-danger";
                // } else {
                //     return "bg-success";
                // }
            } else {
                return null;
            }
        },
        opentimeline(id_permit) {
            let self = this;
            this.modal_timeline = true;
            this.loading_timeline = true;
            this.permit_timline_id = id_permit;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/timeline", {
                    params: {
                        id: id_permit,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    console.log(response, "response data type");
                    self.loading_timeline = false;
                    self.timeline = response.data.data.work_permit_timeline;
                    self.matrix_timeline = response.data.data.work_permit_matrix;
                    Swal.close();
                })
                .catch((e) => {
                    Swal.close();
                    this.axiosCatchError = e.response.data.data;
                    console.log(this.axiosCatchError);
                });
        },
        checkProsesMatrix(timeline_use) {
            var processNow = JSON.parse(timeline_use.approval_end);
            var id_now = timeline_use?.approval_end_id;
            var asignTo = null;
            if (processNow?.is_multi_user == "t") {
                var getMatrix = [];
                if (timeline_use.work_permit_request_matrix.length >= 1) {
                    timeline_use.work_permit_request_matrix.forEach((val) => {
                        if (val.konfig_status_id === id_now) {
                            getMatrix.push(val.user.name);
                        }
                    });
                } else {
                    this.matrix_timeline.forEach((val) => {
                        if (val.konfig_status_id === id_now && val.asign_from_timeline_id == null) {
                            getMatrix.push(val.user.name);
                        }
                    });
                }

                asignTo = getMatrix;
            }

            return asignTo;
        },
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("MMMM Do YYYY, h:mm:ss a");
        },
        fullDateFormat(date) {
            return moment(date).format("MMMM Do YYYY");
        },
        getNamaStatus(key) {
            var timeline_use = this.timeline[key];
            var approval_start = JSON.parse(timeline_use.approval_start);
            if (timeline_use.status_permit_kategori == "Pengajuan" || timeline_use.status_permit_kategori == "Draft") {
                approval_start = JSON.parse(timeline_use.approval_end);
            }
            return approval_start?.mspk_msp_nama;
        },
        getApprove(timeline_use) {
            var approval_start = JSON.parse(timeline_use.approval_start);
            if (timeline_use.status_permit_kategori == "Pengajuan" || timeline_use.status_permit_kategori == "Draft") {
                approval_start = JSON.parse(timeline_use.approval_end);
            }
            // var approval_end = JSON.parse(timeline_use.approval_end);
            var htmls = "<b> Assigned By : </b>" + timeline_use.user.name;
            if (timeline_use?.timeline_kategori == "tolak") {
                htmls = "<b> Rejected By : </b>" + timeline_use.user.name;
            }
            if (timeline_use?.status_permit_kategori == "Proses" && approval_start.is_approval == "t" && timeline_use?.timeline_kategori == "terima") {
                htmls = "<b> Approved By : </b>" + timeline_use.user.name;
            }
            if (timeline_use?.status_permit_kategori == "Pengajuan" || approval_start.mspk_role_id == 42) {
                htmls = "<b> Submited By : </b>" + timeline_use.user.name;
            }
            if (timeline_use?.status_permit_kategori == "Draft") {
                htmls = "<b> Drafted By : </b>" + timeline_use.user.name;
            }
            if (timeline_use?.timeline_kategori == "roleback") {
                htmls = "<b> Roleback By : </b>" + timeline_use.user.name;
            }

            return htmls;
        },
        downloadQr() {
            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "generate/work-permit-qr?id=" + this.data_qr?.id, "_blank");
        },
        exportPDFTimeline(id) {
            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "generate/work-permit-timeline?id=" + id, "_blank");

            // Swal.fire({
            //     title: '<i class="fas fa-spinner fa-spin"></i>',
            //     text: "Loading...",
            //     showConfirmButton: false,
            // });
            // let config = {
            //     method: "get",
            //     url: process.env.VUE_APP_BACKEND_URL_VERSION + "generate/work-permit-timeline",
            //     params: {
            //         id: id,
            //     },
            //     headers: {
            //         Accept: "application/json",
            //         Authorization: "Bearer " + localStorage.access_token,
            //     },
            //     responseType: "blob",
            // };
            // axios(config)
            //     .then(function (response) {
            //         Swal.close();
            //         var blob = new Blob([response.data], { type: "application/pdf" });
            //         var link = document.createElement("a");
            //         link.href = window.URL.createObjectURL(blob);
            //         link.download = "Work Permit Timeline.pdf";
            //         link.click();
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });
        },
        getStatusList(data_detail) {
            var status_berjalan = data_detail?.status_berjalan?.mspk_msp_nama;
            var timeline = data_detail.work_permit_request_approval;
            var last_timeline = timeline.slice(-1)[0];
            // console.log(last_timeline);
            var htmls = status_berjalan + "<br>";
            if (last_timeline.timeline_kategori == "tolak") {
                htmls += "<b>Remark : </b>" + last_timeline.remarks;
            }
            return htmls;
        },
        filterUniqueArray(array) {
            var unique_array = [...new Set(array.map((item) => item.work_permit_type_nama))];
            return unique_array;
        },
    },
};
</script>
<style scoped>
.bodyborder {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    text-align: center;
}
</style>

<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col-xl-3" v-for="(countdata, key_data) in data_counted" :key="key_data">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h4 class="mb-0">{{ countdata.counted }}</h4>
                                <p class="text-muted fw-medium mb-2">{{ countdata.type }}</p>
                            </div>

                            <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                                <span class="avatar-title">
                                    <i :class="`bx bx-file font-size-24`"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 my-3">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <!-- <div class="col-md-3">
                                                <div class="form-group">
                                                    <b for="">Work Permit Type</b>
                                                    <v-select :options="filter_work_type" label="mps_nama" v-model="work_type_selected"></v-select>
                                                </div>
                                            </div> -->
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <b for="">Work Permit Status</b>
                                                    <v-select :options="filter_work_status" label="label" v-model="work_status_selected"></v-select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <br />
                                                <div class="btn btn-primary btn-sm" v-on:click="filterPermohonan"><i class="fa fa-filter"></i> Filter</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0 table-bordered table-sm">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th style="width: 50px">No</th>
                                                <th>Work Permit Number</th>
                                                <th>Work Permit Date</th>
                                                <th>Work Permit Type</th>
                                                <th>Work Permit Status</th>
                                                <th>Initiator Name</th>
                                                <th style="width: 150px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadingTable">
                                                <td colspan="8"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                            </tr>
                                            <tr v-for="(row_data, key_data) in dataTable" :key="key_data" v-else style="vertical-align: middle">
                                                <td class="text-center">
                                                    <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                                                    <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
                                                </td>
                                                <td>
                                                    <div v-if="row_data?.work_permit_no">{{ row_data?.work_permit_no }} <br /></div>
                                                    <div v-else>Data Tidak Tersedia</div>
                                                </td>
                                                <td>
                                                    <div v-if="row_data?.work_permit_date">
                                                        {{ row_data?.work_permit_date }}
                                                    </div>
                                                    <div v-else>Data Tidak Tersedia</div>
                                                </td>
                                                <td>
                                                    <div v-if="row_data?.work_permit_type">
                                                        <ul>
                                                            <li>
                                                                {{ row_data?.work_permit_type }} - ({{ fullDateFormat(row_data.tanggal_mulai) }} - {{ fullDateFormat(row_data.tanggal_berakhir) }}) -
                                                                <label class="badge" :class="getBgList(row_data.tanggal_berakhir)">
                                                                    {{ getDays(row_data.tanggal_berakhir) }}
                                                                </label>
                                                            </li>
                                                            <li v-for="(row_type, key_type) in filterUniqueArray(row_data?.work_permit_request_detail)" :key="key_type">
                                                                {{ row_type }} ({{ fullDateFormat(JSON.parse(row_data?.work_permit_request_detail[key_type]?.detail_data)?.tanggal) }})
                                                                <label class="badge" :class="getBgList(JSON.parse(row_data?.work_permit_request_detail[key_type]?.detail_data)?.tanggal)">
                                                                    {{ getDays(JSON.parse(row_data?.work_permit_request_detail[key_type]?.detail_data)?.tanggal) }}
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div v-else>Data Tidak Tersedia</div>
                                                </td>
                                                <td>
                                                    <div v-html="getStatusList(row_data)"></div>
                                                </td>
                                                <td>
                                                    {{ row_data?.user?.name }}
                                                </td>
                                                <td>
                                                    <div v-if="row_data?.status_permit == 'Draft Work Plan'">
                                                        <router-link :to="{ name: 'edit-work-permit', params: { permitId: row_data.id } }" class="btn btn-sm btn-warning w-100 mb-1" v-if="hak_akses_add_workpermit"><i class="fa fa-edit"></i> Edit</router-link>
                                                        <b-button class="btn btn-sm btn-danger w-100 mb-1" v-if="hak_akses_add_workpermit" v-on:click="changeStatusData(row_data.id)"><i class="fa fa-trash"></i> Delete</b-button>
                                                        <b-button type="button" variant="success" class="btn btn-sm w-100 mb-1" @click="opentimeline(row_data.id)"><i class="fas fa-code-branch"></i> Timeline</b-button>
                                                    </div>
                                                    <div v-else>
                                                        <router-link :to="{ name: 'work-permit-detail', params: { permitId: row_data.id } }" class="btn btn-sm btn-info w-100 mb-1"><i class="fa fa-eye"></i> Detail</router-link>
                                                        <b-button v-if="row_data.status_permit_kategori == 'Selesai'" type="button" variant="warning" class="btn btn-sm w-100 mb-1" @click="showModal(row_data)"><i class="fas fa-qrcode"></i> See QR</b-button>
                                                        <b-button type="button" variant="success" class="btn btn-sm w-100 mb-1" @click="opentimeline(row_data.id)"><i class="fas fa-code-branch"></i> Timeline</b-button>
                                                        <!-- <b-button class="btn btn-sm btn-danger w-100 mb-1" v-if="hak_akses_delete" v-on:click="changeStatusData(row_data.id, 'cancel')"><i class="fa fa-times"></i> Cancel</b-button> -->
                                                        <b-button class="btn btn-sm btn-danger w-100 mb-1" v-if="hak_akses_delete" v-on:click="changeStatusProcess(row_data.id)"><i class="fa fa-times"></i> Roleback Status</b-button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="pt-4">Show {{ showDataFromTable }}-{{ showDataToTable }} From {{ totalDataTable }}</div>
                            </div>
                            <div class="col-md-6">
                                <div style="float: right !important">
                                    <nav aria-label="pagination" class="mt-3">
                                        <ul class="pagination justify-content-start">
                                            <li
                                                v-for="(page, key_page) in pagingTable"
                                                v-bind:class="{
                                                    active: page.active,
                                                }"
                                                :key="key_page"
                                                class="page-item">
                                                <div v-if="page.url" v-on:click="toPage(page.url)" class="page-link c-page-link-number">
                                                    <div v-html="page.label"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <b-modal v-model="modal_qr" hide-footer centered header-class="border-0" title="QR Code Work Permit">
            <div class="mb-4">
                <div class="row">
                    <div class="col-md-12 text-end">
                        <div class="btn btn-sm btn-warning ms-1" @click="downloadQr()"><i class="fa fa-download"></i> Download QR</div>
                    </div>
                    <div class="col-md-12">
                        <table class="table mb-0 table-bordered table-sm" v-if="data_qr">
                            <tbody>
                                <tr>
                                    <th style="width: 150px">Nomor Work Permit</th>
                                    <td style="width: 15px">:</td>
                                    <td>{{ data_qr?.work_permit_no }}</td>
                                </tr>
                                <tr>
                                    <th>Instalasi (Nama Pekerjaan)</th>
                                    <td style="width: 15px">:</td>
                                    <td>
                                        {{ JSON.parse(data_qr?.detail_request)?.instalasi }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Perusahaan / Contractor</th>
                                    <td style="width: 15px">:</td>
                                    <td>
                                        {{ JSON.parse(data_qr?.detail_request)?.performing_task }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Process Owner</th>
                                    <td style="width: 15px">:</td>
                                    <td>
                                        {{ JSON.parse(data_qr?.detail_request)?.process_owner }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Vendor Representative</th>
                                    <td style="width: 15px">:</td>
                                    <td>
                                        {{ JSON.parse(data_qr?.detail_request)?.vendor }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Tanggal Berlaku</th>
                                    <td style="width: 15px">:</td>
                                    <td>{{ JSON.parse(data_qr?.detail_request)?.berlaku_sejak }} - {{ JSON.parse(data_qr?.detail_request)?.hingga_sejak }}</td>
                                </tr>
                                <tr>
                                    <th>QrCode</th>
                                    <td style="width: 15px">:</td>
                                    <td><img :src="be_path + data_qr.qr_path" style="text-align: center; width: 150px" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <!-- <b-button variant="light" v-on:click="modal_qr = false"> <i class="bx bx-x"></i> Close </b-button> -->
            </div>
        </b-modal>

        <b-modal v-model="modal_timeline" hide-footer centered header-class="border-0" title="Timeline Work Permit" size="lg">
            <div class="mb-4">
                <div class="row" v-if="loading_timeline">
                    <div class="col-md-12"><i class="fas fa-spinner fa-spin"></i> Loading...</div>
                </div>
                <div class="row" v-else>
                    <div class="col-md-12 text-end">
                        <div class="btn btn-warning ms-1" @click="exportPDFTimeline(permit_timline_id)"><i class="fas fa-file-pdf"></i> Download Timeline</div>
                    </div>
                    <div class="col-md-12" style="text-align: center">
                        <ul class="verti-timeline list-unstyled">
                            <li class="event-list" v-for="(val, key) in timeline" :key="key">
                                <div class="event-timeline-dot">
                                    <i class="bx bx-right-arrow-circle font-size-18"></i>
                                </div>
                                <div class="d-flex">
                                    <div class="me-3">
                                        <h5 class="font-size-14">
                                            {{ fullDateTimeFormat(val.created_at) }}
                                            <i v-if="val.timeline_kategori == 'tolak'" class="bx bx-right-arrow-alt font-size-16 text-danger align-middle ms-2"></i>
                                            <i v-else class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                        </h5>
                                    </div>
                                    <div class="flex-grow-1 text-start">
                                        <div class="">{{ getNamaStatus(key) }}</div>

                                        <p v-html="getApprove(val)"></p>

                                        <div v-if="val.remarks">
                                            <b>Remark : </b><br />
                                            <p>{{ val.remarks }}</p>
                                        </div>
                                        <div v-if="checkProsesMatrix(val)">
                                            <b>Asign To : </b><br />
                                            <ul>
                                                <li v-for="(val, key) in checkProsesMatrix(val)" :key="key">
                                                    {{ val }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <!-- <b-button variant="light" v-on:click="modal_qr = false"> <i class="bx bx-x"></i> Close </b-button> -->
            </div>
        </b-modal>
        <b-modal v-model="modal_change_status_request" hide-footer centered header-class="border-0" title="Roleback Status Permit">
            <div class="mb-4">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <b for="">Work Permit Status</b>
                            <v-select :options="status_change" label="mspk_msp_nama" v-model="status_change_selected"></v-select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Remark <i class="text-danger">*</i></label>
                            <textarea class="form-control" v-model="catatan_perubahan_status"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button variant="light" v-on:click="modal_change_status_request = false"> <i class="bx bx-x"></i> Close </b-button>
                <b-button variant="primary" v-on:click="prosesPerubahanStatus()"> <i class="bx bx-save"></i> Submit </b-button>
            </div>
        </b-modal>
    </Layout>
</template>
